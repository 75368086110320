import { Show, splitProps } from "solid-js";
import { QuoteProps } from "./model";

export function Quote(props: QuoteProps) {
  // {quote,clases,editable,onQuoteChange, hasValue}
  return (
    <Show
      when={props.options.editable}
      fallback={<p class={`w-full h-265px text-39px font-700  overflow-hidden leading-43px`}>{props.elements.quote}</p>}
    >
      <textarea
        onInput={props.events.onChange}
        class={` text-39px text#n-200 border-dashed border-2 border#n-200 font-700 p-10px overflow-hidden placeholder:text#n-200 leading-43px ${props.class ?? ""} `}
        value={props.elements.quote}
      />
    </Show>
  );
}
